var cookie_notice_id = document.querySelector("#ocm_cookie_consent");

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

var cookie_closer = document.querySelectorAll(".js-cookie-accept");

if( cookie_closer.length ){
    for (var c=0; c<cookie_closer.length; c++){
        var closer = cookie_closer[c];

        if( closer ){
            closer.onclick = function (e) {
                var href = e.target.getAttribute("href");
                if( href ){
                    if( href === "#" ){
                        e.preventDefault();
                    }
                }

                setCookie("ocm-accept-cookies", "accepted", 15);
                cookie_notice_id.classList.add("hidden");
            };
        }
    }
}

if( !getCookie("ocm-accept-cookies") ){
    if( cookie_notice_id ){
        cookie_notice_id.classList.add("active");
    }
}