window.addEventListener("DOMContentLoaded", () => {
  if (!document.body.classList.contains("page-template-2021-review-layout") && !document.body.classList.contains("page-template-game-review-page"))
    return;

  const topBanner = document.querySelector(".review-banner");
  const stickyNav = document.querySelector("#brand_navigation");
  const navBanner = document.querySelector(".navigation__banner");
  const NavItems = Array.from(
    document.querySelectorAll(
      ".brand-page-navigation--link-anchor.brand-page-navigation--link-overview"
    )
  );

  const targetHeadings = Array.from(document.querySelectorAll("h2"));

  const observerOptionsObject = {
    root: null,
    treshhold: 0,
  };

  const navigationHighlightObserverCallback = (entries) => {
    const [entry] = entries;

    if (!entry.isIntersecting || !entry.target.id) return;

    const requiredId = entry.target.id;

    NavItems.forEach((navItem) => {
      navItem.classList.remove("active_navi");
      if (navItem.getAttribute("href") === `#${requiredId}`)
        navItem.classList.add("active_navi");
    });
  };

  const stickyMenuCallback = (entries) => {
    // if (!entries[0].isIntersecting && window.innerWidth < 765)
    //   navBanner.classList.remove("hidden");
    // if (entries[0].isIntersecting) stickyNav.classList.remove("fixed");

    if (!entries[0].isIntersecting) navBanner.classList.remove("hidden");
    if (!entries[0].isIntersecting) stickyNav.classList.add("fixed");
    if (entries[0].isIntersecting) navBanner.classList.add("hidden");
    if (entries[0].isIntersecting) stickyNav.classList.remove("fixed");
  };

  targetHeadings.forEach((heading) => {
    const observer = new IntersectionObserver(
      navigationHighlightObserverCallback,
      observerOptionsObject
    );

    observer.observe(heading);
  });

  const observer = new IntersectionObserver(
    stickyMenuCallback,
    observerOptionsObject
  );

  observer.observe(topBanner);
});
