/**
 * External Dependencies
 */
import "jquery";
import "bootstrap";
import "./home";
import "./cookie-consent";
import "./sticky-nav";

$(document).ready(() => {
    // console.log('Hello world');
    jQuery(".showView").click(function () {
        jQuery(".choose_showpoint").slideToggle();
    });
    jQuery(".showView_1").click(function () {
        jQuery(".choose_showpoint_1").slideToggle();
    });
    jQuery(".showView_2").click(function () {
        jQuery(".choose_showpoint_2").slideToggle();
    });
    jQuery(".search_form_cus img").click(function () {
        jQuery(".search_form_cus_form_inner_outer").addClass("open_search");
    });
    jQuery(".close_search_bar").click(function () {
        jQuery(".search_form_cus_form_inner_outer").removeClass("open_search");
    });
    $('.fin-comments-wrap .nav.nav-tabs li a').on("click", function () {
        if($(this).attr("href")!=undefined){
            $('.fin-comments-wrap .nav.nav-tabs li').removeClass("active");
            $('.fin-comments-wrap .nav.nav-tabs li a').removeClass("active");
            $(this).parent('li').addClass("active");
        }
    });

    jQuery(".tabs-menu a").click(function (event) {
        event.preventDefault();
        jQuery(this)
            .parent()
            .addClass("current");
        jQuery(this)
            .parent()
            .siblings()
            .removeClass("current");
        var tabs = jQuery(this).attr("data-href");
        jQuery(".review-com-tab-content")
            .not(tabs)
            .css("display", "none");
        jQuery(tabs).fadeIn();
    });
    jQuery("body").on("click", ".show_more_review_action", function () {
        var current = jQuery(this);
        current.prev().show();
        var post_id = jQuery(this).attr("data-id");
        var postshow = jQuery(this).attr("data-show");
        var postkey = jQuery(this).attr("data-key");
        var datatotal = jQuery(this).attr("data-total");
        var dataoffset = jQuery(this).attr("data-offset");
        jQuery(this).attr("data-offset", parseInt(datatotal) + 2);
        jQuery(this).hide();
        jQuery.ajax({
            url: "/wp-admin/admin-ajax.php",
            data: {
                action: "get_comment_review",
                "data-id": post_id,
                "data-show": postshow,
                "data-key": postkey,
                datatotal: datatotal,
                dataoffset: dataoffset,
            },
            type: "post",
            success: function (data) {
                if (postshow == "") {
                    jQuery("#all-comments1").append(data);
                    current.prev().hide();
                } else if (postshow == "positive") {
                    jQuery("#positive-comments1").append(data);
                    current.prev().hide();
                } else if (postshow == "neutral") {
                    jQuery("#neutral-comments1").append(data);
                    current.prev().hide();
                } else if (postshow == "negative") {
                    jQuery("#negative-comments1").append(data);
                    current.prev().hide();
                }
            },
        });
        return false;
    });
    // jQuery(window).scroll(function() {
    //   //console.log(jQuery( window ).scrollTop());
    //   if (window.length) {
    //     var scroll = jQuery(window).scrollTop();
    //     var top = jQuery("#brand-page-navigation").offset().top - 100;
    //     if (scroll > top) {
    //       jQuery("#brand-page-navigation")
    //         .fadeIn("3000")
    //         .addClass("sticky-navreviws");
    //       jQuery("#brand-sticky-banner").addClass("sticky-bannerreviws");
    //       jQuery("#brand-sticky-banner").show();
    //     }
    //     if (jQuery(window).scrollTop() < 380) {
    //       jQuery("#brand-page-navigation").removeClass("sticky-navreviws");
    //       jQuery("#brand-sticky-banner").removeClass("sticky-bannerreviws");
    //       jQuery("#brand-sticky-banner").hide();
    //     }
    //   }
    // });
    if (jQuery(window).width() < 767) {
        jQuery(".accord").click(function () {
            jQuery(".accord").removeClass("on");
            jQuery(".accordcont").slideUp("normal");
            if (
                jQuery(this)
                    .next()
                    .is(":hidden") == true
            ) {
                jQuery(this).addClass("on");
                jQuery(this)
                    .next()
                    .slideDown("normal");
            }
        });
        jQuery(".accord")
            .mouseover(function () {
                //jQuery(this).addClass('over');
            })
            .mouseout(function () {
                //jQuery(this).removeClass('over');
            });
        jQuery(".accordcont").hide();
        jQuery(".open_accordcont").show();
    }
    jQuery("#brand-page-navigation a").click(function () {
        jQuery("#brand-page-navigation a").removeClass("active_navi");
        jQuery(this).addClass("active_navi");
    });
    jQuery(".brand-page-navigation--link-anchor").on("click", function () {
        event.preventDefault();
        window.disableHoverWhileInTransit = true;
        jQuery(".brand-page-navigation--link-anchor").removeClass("active_navi");
        event.preventDefault();
        jQuery(this).addClass("active_navi");
        if (jQuery(window).width() < 767) {
            if (jQuery(".brand-page-navigation").hasClass("sticky-navreviws")) {
                var offset = 50;
            } else {
                var offset = 140;
            }
        } else {
            if (jQuery(".brand-page-navigation").hasClass("sticky-navreviws")) {
                var offset = 140;
            } else {
                var offset = 195;
            }
        }
        console.log(offset);
        jQuery("html, body").animate(
            {scrollTop: jQuery(jQuery(this).attr("href")).offset().top - offset},
            500,
            function () {
                window.disableHoverWhileInTransit = false;
                jQuery(window).scroll();
            }
        ); // trigger a scroll event and thereby our nav highlightnavigation
    });
    var $navigationLinks = jQuery(".brand-page-navigation--link-anchor");
    // cache (in reversed order) the sections
    var $sections = jQuery(
        jQuery(".brand-page-navigation--link-div")
            .get()
            .reverse()
    );

    // map each section id to their corresponding navigation link
    var sectionIdTonavigationLink = {};
    $sections.each(function () {
        var id = jQuery(this).attr("id");
        sectionIdTonavigationLink[id] = jQuery(
            ".brand-page-navigation--link a[href=\\#" + id + "]"
        );
    });

    function highlightNavigation() {
        if (
            window.disableHoverWhileInTransit &&
            true === window.disableHoverWhileInTransit
        ) {
            return false; // cancel out here
        }
        // get the current vertical position of the scroll bar
        var scrollPosition = jQuery(window).scrollTop();

        // iterate the sections
        $sections.each(function () {
            var currentSection = jQuery(this);
            // get the position of the section
            var offset = jQuery(".brand-page-navigation").hasClass("sticky")
                ? 155
                : 300;
            var sectionTop = currentSection.offset().top - offset;

            // if the user has scrolled over the top of the section
            if (scrollPosition >= sectionTop) {
                // get the section id
                var id = currentSection.attr("id");
                // get the corresponding navigation link
                var $navigationLink = sectionIdTonavigationLink[id];
                // if the link is not active
                if (!$navigationLink.hasClass("active_navi ")) {
                    // remove .active class from all the links
                    $navigationLinks.removeClass("active_navi ");
                    // add .active class to the current link
                    $navigationLink.addClass("active_navi ");
                }
                // we have found our section, so we return false to exit the each loop
                return false;
            }
        });
    }

    function throttle(fn, interval) {
        var lastCall, timeoutId;
        return function () {
            var now = new Date().getTime();
            if (lastCall && now < lastCall + interval) {
                // if we are inside the interval we wait
                clearTimeout(timeoutId);
                timeoutId = setTimeout(function () {
                    lastCall = now;
                    fn.call();
                }, interval - (now - lastCall));
            } else {
                // otherwise, we directly call the function
                lastCall = now;
                fn.call();
            }
        };
    }

    jQuery(window).scroll(throttle(highlightNavigation, 100));
    // Wait for the DOM to be ready
    jQuery(function () {
        // Initialize form validation on the registration form.
        // It has the name attribute "registration"
        var commentform = jQuery("#commentform");

        if (commentform.length) {
            $('.comment-form .submit').on('click', function (e) {

                e.preventDefault();

                var button = $(this);
                var button_text = button.val();
                var form = $(this).closest('form');
                var name = "";
                var email = "";
                var comment = form.find('#comment').val();

                var error = false;
                if (form.find('#author').length) {
                    form.find('#author').removeClass("error");
                    name = form.find('#author').val();
                    if (name == "") {
                        form.find('#author').addClass("error");
                        error = true;
                    }
                }
                if ($('body.page-template-game-review-page').length) {
                    var rating = form.find('input[name="rating"]');
                    if (rating.is(':checked')) {
                        form.find('.comment-form-rating .error').remove();
                    } else {
                        if (!form.find('.comment-form-rating .error').length) {
                            form.find('.comment-form-rating').append('<div class="error">Bitte geben Sie eine Bewertung ab</div>');
                        }
                        error = true;
                    }
                }
                if (form.find('#email').length) {
                    form.find('#email').removeClass("error");
                    email = form.find('#email').val();
                    if (email == "") {
                        form.find('#email').addClass("error");
                        error = true;
                    } else {
                        var emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            emailToValidate = email;
                        if (!emailReg.test(emailToValidate) || emailToValidate == "") {
                            form.find('#email').addClass("error");
                            error = true;
                        }
                    }
                }

                form.find('#comment').removeClass("error");
                if (comment == "") {
                    form.find('#comment').addClass("error");
                    error = true;
                }

                if (form.find('.comment-notification').length) {
                    form.find('.comment-notification').remove();
                }

                if (!error) {
                    var form_data = form.serialize();

                    //honeypot checking
                    if (form.find('textarea[name="comment"]').val() == "") {
                        comment = form.find('textarea[name="comment"]').val(comment);

                        form_data = form.serialize();
                    }

                    // ajax request
                    $.ajax({
                        type: 'POST',
                        url: '/wp-admin/admin-ajax.php', // admin-ajax.php URL
                        dataType: 'json', // admin-ajax.php URL
                        data: form_data + '&action=finbrandcomment', // send form data + action parameter
                        beforeSend: function (xhr) {
                            // what to do just after the form has been submitted
                            button.addClass('loadingform').val("Erstelle Bewertung…");
                        },
                        error: function (request, status, error) {
                            form.append('<div class="comment-notification error" >' + 'Eintragung fehlgeschlagen ' + '</div>');
                        },
                        success: function (response) {
                            var comment_save_status = response.comment_save_status;
                            var comment_status = response.comment_status;
                            if (parseInt(comment_save_status) <= 0) {
                                form.append('<div class="comment-notification error" >' + response.comment_notification + '</div>');
                            } else {
                                var classification = response.classification;
                                var comment_html = response.comment_html;

                                $('.fin-comments-wrap .tab-content #all-comments1').prepend(comment_html);
                                $('.fin-comments-wrap .tab-content #' + classification + '-comments').prepend(comment_html);
                                $('.fin-comments-wrap .tab-' + classification + '-comments-cta').attr('data-toggle', 'tab');
                                $('.fin-comments-wrap .tab-' + classification + '-comments-cta').attr('href', '#' + classification + '-comments');
                                $('.fin-comments-wrap .tab-all-comments-cta').attr('data-toggle', 'tab');
                                $('.fin-comments-wrap .tab-all-comments-cta').attr('href', '#' + classification + '-comments');

                                form.append('<div class="comment-notification success" >' + response.comment_notification + '</div>');

                                var total_comment_count = $('.fin-comments-wrap .tab-content #all-comments1 .review--container').length;
                                var classification_comment_count = $('.fin-comments-wrap .tab-content #' + classification + '-comments .review--container').length;

                                $('.fin-comments-wrap .tab-all-comments-cta').html(response.all_text + ' (' + total_comment_count + ')');

                                if ($('.brand-page-navigation--link-fin-comments span').length) {
                                    $('.brand-page-navigation--link-fin-comments span').html(response.review_all_link_text + ' (' + total_comment_count + ')');
                                }

                                $('.fin-comments-wrap .tab-' + classification + '-comments-cta').html(response.classification_text + ' (' + classification_comment_count + ')');
                            }

                            // clear comment fields
                            if (form.find('#author').length) {
                                form.find('#author').val("");
                            }

                            if (form.find('#email').length) {
                                form.find('#email').val("");
                            }

                            $('#comment').val('');
                        },
                        complete: function () {
                            // what to do after a comment has been added
                            button.removeClass('loadingform').val(button_text);
                        }
                    });
                }
            });
            // jQuery("#commentform").validate({
            //   // Specify validation rules
            //   rules: {
            //     // The key name on the left side is the name attribute
            //     // of an input field. Validation rules are defined
            //     // on the right side
            //     firstname: "required",
            //     lastname: "required",
            //     author: "required",
            //     review_comnt_title: "required",
            //     rating: "required",
            //     comment: "required",
            //     email: {
            //       required: true,
            //       // Specify that email should be validated
            //       // by the built-in "email" rule
            //       email: true,
            //     },
            //     password: {
            //       required: true,
            //       minlength: 5,
            //     },
            //   },
            //   // Specify validation error messages
            //   messages: {
            //     firstname: "Please enter your firstname",
            //     lastname: "Please enter your lastname",
            //     author: "Please enter your name",
            //     review_comnt_title: "Please enter review title",
            //     comment: "Please enter review",
            //     rating: "Please enter your rating",
            //     password: {
            //       required: "Please provide a password",
            //       minlength: "Your password must be at least 5 characters long",
            //     },
            //     email: "Please enter a valid email address",
            //   },
            //   // Make sure the form is submitted to the destination defined
            //   // in the "action" attribute of the form when valid
            //   submitHandler: function(form) {
            //     form.submit();
            //   },
            // });
        }
    });
    setTimeout(function () {
        console.log("click scroll");
        load_scroll();
    }, 100);
});
jQuery(window).scroll(function () {
    var scroll = jQuery(window).scrollTop();
    var sect = jQuery(".custmon_countstk");
    if (sect.length) {
        var top = jQuery(".custmon_countstk").offset().top - 500;
        if (scroll > top) {
            timecounterticker();
            jQuery(".betoffers-inner ").removeClass("custmon_countstk");
        }
    }
    //jQuery( window ).scroll( function () {
    //console.log(jQuery( window ).scrollTop());
    //if ( jQuery( window ).scrollTop() > 70 ) {
    //jQuery('.header').fadeIn("3000").addClass('sticky-nav');
    //}
    //if ( jQuery( window ).scrollTop() < 70 ) {
    //jQuery('.header').removeClass('sticky-nav');
    //}
    //});
});

function load_scroll() {
    jQuery(".brand-page-navigation--link-anchor").on("click", function () {
        event.preventDefault();
        window.disableHoverWhileInTransit = true;
        jQuery(".brand-page-navigation--link-anchor").removeClass("active_navi");
        event.preventDefault();
        jQuery(this).addClass("active_navi");
        if (jQuery(window).width() < 767) {
            if (jQuery(".brand-page-navigation").hasClass("sticky-navreviws")) {
                var offset = 50;
            } else {
                var offset = 140;
            }
        } else {
            if (jQuery(".brand-page-navigation").hasClass("sticky-navreviws")) {
                var offset = 140;
            } else {
                var offset = 195;
            }
        }
        console.log(offset + " offset");
        jQuery("html, body").animate(
            {scrollTop: jQuery(jQuery(this).attr("href")).offset().top - offset},
            500,
            function () {
                window.disableHoverWhileInTransit = false;
                jQuery(window).scroll();
            }
        ); // trigger a scroll event and thereby our nav highlightnavigation
    });
}

function timecounterticker() {
    jQuery(".countstk").each(function () {
        var num = Math.floor(Math.random() * 9) + 1;
        jQuery(this).text(num);
        jQuery(this)
            .prop("Counter", 0)
            .animate(
                {
                    Counter: jQuery(this).text(),
                },
                {
                    duration: 2000,
                    easing: "swing",
                    step: function (now) {
                        jQuery(this).text(Math.ceil(now));
                    },
                }
            );
    });
}

jQuery(function () {
    var section = jQuery(".overallrating_score_rate_prefill");
    var commentsection = jQuery(".comment-count-slider--value");

    function loadDaBars() {
        jQuery(".overallrating_score_rate_prefill").each(function () {
            jQuery(this)
                .data("origWidth", jQuery(this).width())
                .width(0)
                .animate(
                    {
                        width: jQuery(this).data("origWidth"),
                    },
                    1500
                );
        });
    }

    function commentloadDaBars() {
        jQuery(".comment-count-slider--value").each(function () {
            jQuery(this)
                .data("origWidth", jQuery(this).width())
                .width(0)
                .animate(
                    {
                        width: jQuery(this).data("origWidth"),
                    },
                    1500
                );
        });
    }

    jQuery(document).bind("scroll", function (ev) {
        if (document.length) {
            var scrollOffset = jQuery(document).scrollTop();
            var containerOffset = section.offset().top - window.innerHeight;
            var commentscrollOffset = jQuery(document).scrollTop();
            var commentcontainerOffset =
                commentsection.offset().top - window.innerHeight;
            if (scrollOffset > containerOffset) {
                loadDaBars();
                jQuery(document).unbind("scroll");
            } else if (commentscrollOffset > commentcontainerOffset) {
                commentloadDaBars();
                // unbind event not to load scrolsl again
                jQuery(document).unbind("scroll");
            }
        }
    });
});
